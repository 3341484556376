const DiagonalLine = () => (
  <div
    style={{
      width: '6px',
      height: '100%',
      backgroundColor: 'rgb(162 162 162)',
      transform: 'rotate(45deg) translate(9px, -8px)',
      borderRight: 'solid 2px white',
      borderLeft: 'solid 2px white',
      borderRadius: '1px'
    }}
  />
)

export default DiagonalLine
