// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageadressbtn{
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white)!important;
  width: 200;
  float: right;
  margin-top: 0;
  padding: .5em !important;
  text-align: center !important;
  border-radius: var(--bradius);
  line-height: 2 !important;
}

.manageadressbtn span{
  color: var(--mf-primary-color) !important;
}

.manageadressbtn:hover, .manageadressbtn:focus, .manageadressbtn:active {
  border: 2px solid var(--mf-primary-color) !important;
  background-color: var(--mf-transparent) !important;
  color: var(--mf-primary-color) !important;
}

.btn-pressed{
  background-color: var(--mf-primary-color) !important;
}
.btn-pressed span{
  color: var(--mf-white) !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/Shop/DigitalProofingForms/FormAddressWrapper.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,oDAAoD;EACpD,gCAAgC;EAChC,UAAU;EACV,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,6BAA6B;EAC7B,6BAA6B;EAC7B,yBAAyB;AAC3B;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,oDAAoD;EACpD,kDAAkD;EAClD,yCAAyC;AAC3C;;AAEA;EACE,oDAAoD;AACtD;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".manageadressbtn{\n  border: 2px solid var(--mf-primary-color) !important;\n  background-color: var(--mf-primary-color) !important;\n  color: var(--mf-white)!important;\n  width: 200;\n  float: right;\n  margin-top: 0;\n  padding: .5em !important;\n  text-align: center !important;\n  border-radius: var(--bradius);\n  line-height: 2 !important;\n}\n\n.manageadressbtn span{\n  color: var(--mf-primary-color) !important;\n}\n\n.manageadressbtn:hover, .manageadressbtn:focus, .manageadressbtn:active {\n  border: 2px solid var(--mf-primary-color) !important;\n  background-color: var(--mf-transparent) !important;\n  color: var(--mf-primary-color) !important;\n}\n\n.btn-pressed{\n  background-color: var(--mf-primary-color) !important;\n}\n.btn-pressed span{\n  color: var(--mf-white) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
