import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Col, Input } from 'reactstrap'
import { useProductAvailableOptions } from '../../../../hooks/useProductAvailableOptions'
import dollar_price from '../../../general/DollarPrice'
import { mapDispatchToProps } from '../../productPageFunctions'

/**
 * @typedef {Object} ConfigurableProductProps
 * @property {number} index - The index of the product
 * @property {Object} variant - The variant object
 * @property {Object} product - The product object
 * @property {Object} configSelection - The current selections
 * @property {function} updateConfigSelection - The callback that updates selections
 * @property {Object} currentLocation - The current location object
 *
 */

/**
 * ConfigurableProduct component
 *
 * @description - renders a single product as part of a bundle
 *
 * @param {ConfigurableProductProps} props - the component properties
 * @returns {JSX.Element} - the rendered component corresponding to each product as part of a bundle
 */
const ConfigurableProduct = ({
  index,
  variant,
  product,
  configSelection,
  updateConfigSelection,
  currentLocation,
  availableSkus,
  stocks,
  fetchInventory
}) => {
  const productAvailableSkus = useMemo(() => ({ ...product, available_skus: availableSkus }), [product, availableSkus])
  const availableOptions = useProductAvailableOptions(
    productAvailableSkus,
    configSelection[product.bundle_group_product_id]?.selected_options,
    {
      stocks,
      fetchInventory
    }
  )

  // define onChange for inputs
  const handleChange = useCallback(
    e => {
      // event will be option ID
      const option_id = parseInt(e.target.value, 10)
      const variant_id = variant.variant_id

      // select the option by the passed in option ID
      const selectedOption = variant.nested_data.find(e => e.id === option_id)
      selectedOption.idx = index
      // send the necessary data to redux
      updateConfigSelection({
        product_id: product.product_id,
        bundle_group_product_id: product.bundle_group_product_id,
        variant_id,
        option: selectedOption
      })
    },
    [variant, index, product, updateConfigSelection]
  )

  const selectionsForThisProduct = configSelection[product.bundle_group_product_id]
    ? configSelection[product.bundle_group_product_id].selected_options
    : {}

  return (
    <Col className="mt-1" xs="12" md="6">
      <b>{variant.display_name ? variant.display_name : variant.name}</b>
      <Input
        className="mt-1 mb-0"
        size="sm"
        type="select"
        value={selectionsForThisProduct[variant.variant_id] ? selectionsForThisProduct[variant.variant_id].id : '-1'}
        onChange={handleChange}
      >
        <option value="-1" disabled>
          Please select...
        </option>

        {/* Options */}
        {variant.nested_data &&
          variant.nested_data.map((option, i) => {
            let optionInputLabel = option.name
            if (option.has_price_modifier === true && currentLocation.show_price) {
              const additionalPriceCents = option.price_modifier.price_modifier_cents

              optionInputLabel = (
                <>
                  {optionInputLabel} + {dollar_price(additionalPriceCents)}
                </>
              )
            }

            return (
              <option key={i} value={option.id}>
                {optionInputLabel}
                {availableOptions.allAvailable
                  ? false
                  : !availableOptions[variant.variant_id]?.has(option.sku_code)
                    ? ' - Out of Stock'
                    : ''}
              </option>
            )
          })}
      </Input>
    </Col>
  )
}

ConfigurableProduct.propTypes = {
  index: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  updateConfigSelection: PropTypes.func.isRequired,
  configSelection: PropTypes.object.isRequired,
  currentLocation: PropTypes.object.isRequired,
  availableSkus: PropTypes.array,
  stocks: PropTypes.object,
  fetchInventory: PropTypes.func.isRequired
}

const mapStateToProps = (state, { product }) => {
  return {
    configSelection: state.productPage.configurableSelection,
    currentLocation: state.currentLocation,
    availableSkus: state.productPage.availableSkus[product.product_id],
    stocks: state.stocks
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableProduct)
