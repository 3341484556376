// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iron-progress-bar-inline {
    width: 70% !important;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Shop/ProductList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".iron-progress-bar-inline {\n    width: 70% !important;\n    margin: auto;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
