import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Input } from 'reactstrap'
import { indefinite } from '../../util/utils'
import dollar_price from '../general/DollarPrice'
import './ProductVariantSelect.css'
import DiagonalLine from './DiagonalLine'

/**
 * @typedef {Object} ProductVariantSelectProps
 * @property {Object} product - The product object
 * @property {Object} selectedOptionsByVariantId - The selected options by variant id
 * @property {function} selectVariant - The callback that selects a variant
 * @property {Object} availableOptions - The available options
 * @property {Object} currentLocation - The current location
 */

/**
 * ProductVariantSelect - The component for selecting product variants
 *
 * @param {ProductVariantSelectProps} props - The properties of the component
 * @returns {JSX.Element} The rendered ProductVariantSelect component
 */
const ProductVariantSelect = props => {
  const { selectedOptionsByVariantId, product } = props
  const variants = product.variants

  const extraPrice = cents => {
    const value = dollar_price(cents)
    if (cents > 0) {
      return <> + {value}</>
    } else if (cents < 0) {
      return <> - {value}</>
    } else {
      return ''
    }
  }

  // returns true if user has selected an option for a given variant id
  const selectionMade = variant_id => !!selectedOptionsByVariantId[variant_id]

  const getOptionExtraPrice = option => {
    const priceModifierData = option.price_modifier
    if (priceModifierData) {
      return extraPrice(priceModifierData.price_modifier_cents)
    }
    return null
  }

  const isOptionAvailable = useCallback(
    (variant, option) => {
      if (props.availableOptions.allAvailable) {
        return true
      }
      return props.availableOptions[variant.variant_id]?.has(option.sku_code)
    },
    [props.availableOptions]
  )

  // sort Swatch options by disabled/enabled states
  const sortedVariants = useMemo(() => {
    const variantOptions = {}
    variants.forEach(variant => {
      if (variant.display_type === 'Swatch') {
        variantOptions[variant.variant_id] = [
          ...variant.nested_data.filter(opt => props.availableOptions[variant.variant_id]?.has(opt.sku_code)),
          ...variant.nested_data.filter(opt => !props.availableOptions[variant.variant_id]?.has(opt.sku_code))
        ]
        return
      }
      variantOptions[variant.variant_id] = variant.nested_data
    })
    return variantOptions
  }, [variants, props.availableOptions])

  const getNotAvailableSuffix = useCallback(
    (variantId, option) => {
      const selectedOptions = {
        ...selectedOptionsByVariantId,
        [variantId]: option
      }
      const fullSku = `${props.product.sku}${props.buildSkuSuffix(selectedOptions)}`

      if (Object.prototype.hasOwnProperty.call(props.stocks, fullSku)) {
        return ' - Out of Stock'
      }
      return ' - Not available'
    },
    [props.product, props.selectedOptionsByVariantId, props.buildSkuSuffix, props.stocks]
  )

  return (
    <div>
      {variants.map((variant, i) => {
        let extraPrice
        const selectedOption = selectedOptionsByVariantId[variant.variant_id]
        if (selectedOption && selectedOption.has_price_modifier === true) {
          extraPrice = getOptionExtraPrice(selectedOption)
        }

        // for variants using buttons/swatch
        if (variant.display_type === 'Button' || variant.display_type === 'Swatch') {
          return (
            <Row className="variant-options p0 ml-0 mb-20" key={i}>
              <div className="config-option ml-0 w-100">
                <div>
                  {!selectionMade(variant.variant_id) ? (
                    <b>Please select {indefinite(variant.display_name ? variant.display_name : variant.name)}</b>
                  ) : (
                    <>
                      <b>{variant.display_name ? variant.display_name : variant.name}: </b>
                      {selectedOption.name}
                      {props.currentLocation.show_price ? extraPrice : null}
                    </>
                  )}
                </div>

                {sortedVariants[variant.variant_id].map((option, index) => {
                  const isAvailable = isOptionAvailable(variant, option)
                  const isVariantOptionSelected = () => {
                    if (selectedOptionsByVariantId[variant.variant_id]) {
                      return selectedOptionsByVariantId[variant.variant_id].id === option.id
                    } else {
                      return false
                    }
                  }

                  let conditionalStyle = {}
                  // modify background color if hex is white
                  const patt = /#*[fF]{3,6}/gm
                  if (patt.test(option.css_hex)) {
                    conditionalStyle = {
                      border: '2px solid #cccccc'
                    }
                  } else {
                    conditionalStyle = {
                      border: !isAvailable ? '1px solid white' : '2px solid #ffffff'
                    }
                  }

                  return (
                    <React.Fragment key={index}>
                      <Button
                        disabled={props.isLoading}
                        id={option.id}
                        value={option.id}
                        onClick={event => props.selectVariant(event, variant, i)}
                        fontSize="50%"
                        className={
                          isVariantOptionSelected()
                            ? variant.display_type === 'Swatch'
                              ? 'is-swatch selected-variant-is-swatch'
                              : 'is-button selected-variant-is-button'
                            : variant.display_type === 'Swatch'
                              ? 'is-swatch'
                              : 'is-button'
                        }
                        style={{
                          color: option.css_hex ? option.css_hex : '#333333',
                          backgroundColor:
                            variant.display_type === 'Swatch' ? option.css_hex : 'rgb(170 168 168 / 34%)',
                          backgroundImage: option.icon.thumb.url ? `url(${option.icon.thumb.url})` : null,
                          textDecoration: 'none',
                          textAlign: 'center',
                          display: 'inline-block',
                          margin:
                            variant.display_type !== 'Button' && !isAvailable ? '8px 9px 7px 2px' : '5px 6px 5px 0px',
                          padding: 'inherit',
                          opacity: !isAvailable || props.isLoading ? 0.5 : 1,
                          ...(variant.display_type !== 'Button' && !isAvailable
                            ? {
                                border: 'solid white 1px',
                                outline: 'solid rgb(162 162 162) 2px',
                                width: '30px',
                                height: '30px'
                              }
                            : {}),
                          ...conditionalStyle
                        }}
                      >
                        {variant.display_type === 'Button' ? option.name : ''}
                        {variant.display_type === 'Swatch' && !isAvailable ? (
                          <DiagonalLine type={variant.display_type} />
                        ) : null}
                      </Button>
                    </React.Fragment>
                  )
                })}
              </div>
            </Row>
          )
        } else {
          return (
            <div key={i} className="config-option ml-0">
              <label>{variant.display_name ? variant.display_name : variant.name}</label>
              <Input
                type="select"
                id={`${variant.variant_id}`}
                index={i}
                className="w-100"
                onChange={event => props.selectVariant(event, variant, i)}
                value={
                  selectedOptionsByVariantId[variant.variant_id]
                    ? selectedOptionsByVariantId[variant.variant_id].id
                    : -1
                }
              >
                <option disabled value="-1">
                  Please Select
                </option>
                {sortedVariants[variant.variant_id].map((option, index) => {
                  let extraPrice = null
                  const isAvailable = isOptionAvailable(variant, option)
                  if (option.has_price_modifier === true) {
                    extraPrice = getOptionExtraPrice(option)
                  }
                  return (
                    <option key={index} value={option.id} disabled={props.isLoading}>
                      {option.name}
                      {props.currentLocation.show_price ? extraPrice : null}
                      {!isAvailable ? getNotAvailableSuffix(variant.variant_id, option, i) : null}
                    </option>
                  )
                })}
              </Input>
            </div>
          )
        }
      })}
      <hr className="w-100 ml-0" />
    </div>
  )
}

ProductVariantSelect.propTypes = {
  product: PropTypes.object,
  selectedOptionsByVariantId: PropTypes.object,
  selectVariant: PropTypes.func,
  availableOptions: PropTypes.object,
  currentLocation: PropTypes.object,
  isLoading: PropTypes.bool,
  stocks: PropTypes.object,
  buildSkuSuffix: PropTypes.func
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    isLoading: state.productPage.inventoryLoading,
    stocks: state.stocks
  }
}

export default connect(mapStateToProps)(ProductVariantSelect)
