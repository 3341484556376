// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variant-options {
  width: 100%;
  display: inline-block;
}

.selected-option {
  text-align: center!important;
  font-weight:bolder;
  display: inline-block;
  color: #747574;
}

.variants{
  color:#333333 !important;
}

.variants:hover{
  background-color:var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}

.is-swatch{
  border-radius: 100% !important;
  width: 35px;
  height: 35px;
  border: 2px solid #ffffff;
}

.is-swatch:hover, .is-swatch:active, .selected-variant-is-swatch{
  box-shadow: 0 0 0 3px #c7c7c7
}

.is-button{
  width: 80px;
  border: none !important;
  height: 50px;
  border-radius: var(--bradius);
  color: #333333 !important;
  -webkit-border-radius: var(--bradius);
  -moz-border-radius: var(--bradius);
  -ms-border-radius: var(--bradius);
  -o-border-radius: var(--bradius);
}

.is-button:hover, .is-button:active, .is-button:focus, .selected-variant-is-button {
  box-shadow: 0 0 0 0px #ffffff !important;
  border: none !important;
  background-color: var(--mf-primary-color) !important;
  color: var(--mf-white) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shop/ProductVariantSelect.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mDAAmD;EACnD,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE;AACF;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,6BAA6B;EAC7B,yBAAyB;EACzB,qCAAqC;EACrC,kCAAkC;EAClC,iCAAiC;EACjC,gCAAgC;AAClC;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,oDAAoD;EACpD,iCAAiC;AACnC","sourcesContent":[".variant-options {\n  width: 100%;\n  display: inline-block;\n}\n\n.selected-option {\n  text-align: center!important;\n  font-weight:bolder;\n  display: inline-block;\n  color: #747574;\n}\n\n.variants{\n  color:#333333 !important;\n}\n\n.variants:hover{\n  background-color:var(--mf-primary-color) !important;\n  color: var(--mf-white) !important;\n}\n\n.is-swatch{\n  border-radius: 100% !important;\n  width: 35px;\n  height: 35px;\n  border: 2px solid #ffffff;\n}\n\n.is-swatch:hover, .is-swatch:active, .selected-variant-is-swatch{\n  box-shadow: 0 0 0 3px #c7c7c7\n}\n\n.is-button{\n  width: 80px;\n  border: none !important;\n  height: 50px;\n  border-radius: var(--bradius);\n  color: #333333 !important;\n  -webkit-border-radius: var(--bradius);\n  -moz-border-radius: var(--bradius);\n  -ms-border-radius: var(--bradius);\n  -o-border-radius: var(--bradius);\n}\n\n.is-button:hover, .is-button:active, .is-button:focus, .selected-variant-is-button {\n  box-shadow: 0 0 0 0px #ffffff !important;\n  border: none !important;\n  background-color: var(--mf-primary-color) !important;\n  color: var(--mf-white) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
